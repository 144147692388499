import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices2";

import { ModalContainer } from "../shared/ModalContainer";
import { modalSizes } from "../shared/ModalContainer";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";

import "./OptionsField.css";


export const EmployeeOptionsField = ({
                                      selectedOptions, setSelectedOptions,
                                      disabled,
                                    }) => {
  const auth = useAuth();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const onOpenClicked = () => {
    setIsModalOpened(true);
  }

  const onCloseClicked = () => {
    updateButtonLabel();
    setIsModalOpened(false);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(0);

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    updateButtonLabel();
  }, [selectedOptions])

  const fetchEmployees = () => {
    setIsLoading(true);
    refServices.fetchEmployeesListByBranch({ 'uid': auth.getUserId() })
      .then((response) => {
        const _employeeBranches = response['data'];
        const _total = 0;
        setEmployeeBranchGroups(_employeeBranches);
        console.log(_employeeBranches);
        for (let branch of _employeeBranches) {
          console.log(branch);
          _total += branch.employees.length;
          // if (branch['employees'] && branch['employees'].length > 0) {
          //   _total += branch['employees'].length;
          // }
        }
        setTotalEmployees(_total);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const [buttonLabel, setButtonLabel] = useState("Select...");

  const updateButtonLabel = () => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setButtonLabel("Select...");
    }
    // else if (selectedOptions.length === totalEmployees) {
    //   setButtonLabel("All selected");
    // }
    // else {
    //   setButtonLabel(`${selectedOptions.length} of ${totalEmployees} selected`);
    // }
    else {
      setButtonLabel(`${selectedOptions.length} selected`)
    }

    // else {
    //   let _label = "";
    //   let _selectedNames = [];
    //   let _selectedOptions = [];
    //   if (selectedOptions) {
    //     // NOTE(yemon): Check https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#comparefn
    //     // for toSorted(compareFn) semantics.
    //     _selectedOptions = selectedOptions.toSorted((a, b) => a - b);
    //   }

    //   // NOTE(yemon): The button label will only take a max of 3 names into account, (2 name + remaining count)
    //   // so there is absolutely no need to go through the entire `selectedOptions` array.
    //   for (let i = 0; i < Math.max(3, _selectedOptions.length); i += 1) {
    //     const businessType = employeeBranchGroups.find((item) => item['id'] === _selectedOptions[i]);
    //     if (businessType) {
    //       _selectedNames.push(businessType['name'].trim());
    //     }
    //   }

    //   if (_selectedNames.length === 1) {
    //     _label = _selectedNames[0];
    //   }
    //   else if (_selectedNames.length === 2) {
    //     _label = `${_selectedNames[0]} + ${_selectedNames[1]}`;
    //   }
    //   else if (_selectedNames.length >= 3) {
    //     _label = `${_selectedNames[0]} + ${_selectedNames[1]} + ${_selectedOptions.length - 2} more`;
    //   }
    //   else {
    //     // NOTE(yemon): Shouldn't happen. Just there to serve as a catch-all solution, just in case.
    //     _label = `Selected ${_selectedOptions.length} options`;
    //   }
    //   setButtonLabel(_label);
    // }
  }

  return (
    <div className={"options-field"}>
      <EmployeeOptionsModal isOpen={isModalOpened} onRequestClose={onCloseClicked} isLoading={isLoading} 
                            employeeBranchGroups={employeeBranchGroups} totalEmployees={totalEmployees}
                            selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />

      <button type={"button"} className={"btn btn-secondary"} disabled={disabled || isLoading}
              title={"Click to choose employees to apply."}
              onClick={onOpenClicked}>
        {buttonLabel}
      </button>
    </div>
  )
}

const EmployeeOptionsModal = ({
                                isOpen, onRequestClose, isLoading, 
                                employeeBranchGroups, totalEmployees,
                                selectedOptions, setSelectedOptions,
                              }) => {
  const getDescription = () => {
    // let count = 0;
    // if (selectedOptions) {
    //   count = selectedOptions.length;
    // }
    // return `${count} of ${employeeBranchGroups.length} total selected`;
    // return `${selectedOptions.length} of ${totalEmployees} selected.`;
    return `${selectedOptions.length} selected.`;
  }

  const isAllSelected = () => {
    // let count = 0;
    // if (selectedOptions) {
    //   count = selectedOptions.length;
    // }
    //return selectedOptions.length === totalEmployees;
    return false;
  }

  const onSelectAllChecked = (ev, checked) => {
    let _allOptions = [];
    if (checked) {
      for (let branch of employeeBranchGroups) {
        if (branch['employees'] && branch['employees'].length > 0) {
          _allOptions.push(...branch['employees']['id']);
        }
      }
      setSelectedOptions(_allOptions);
    }
    else {
      setSelectedOptions([]);
    }
  }

  const isSelected = (employeeId) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return false;
    }
    return selectedOptions.some((id) => id === employeeId);
  }

  const onSelectChecked = (ev, employeeId, checked) => {
    const alreadySelected = (_id) => {
      return _id === employeeId;
    }

    // NOTE(yemon): The many-funsies in the world of JavaScript.
    // Array.slice() "force clone" the entire array into a completely new array.
    let _selectedOptions = selectedOptions.slice();

    if (checked && !_selectedOptions.some(alreadySelected)) {
      _selectedOptions.push(employeeId);
      setSelectedOptions(_selectedOptions);
    }

    if (!checked && _selectedOptions.some(alreadySelected)) {
      let existingIndex = _selectedOptions.findIndex(alreadySelected);
      if (existingIndex !== -1) {
        _selectedOptions.splice(existingIndex, 1);
        setSelectedOptions(_selectedOptions);
      }
    }
  }

  return (
    <ModalContainer elementId={"business-type-options-modal"}
                    isOpen={isOpen} onRequestClose={onRequestClose}
                    modalSize={modalSizes.tiny} height={720}
                    title={"Select Employees"}
                    shortDescription={getDescription()}>
      <div className={"modal-data-table options-field-elements"}>
        <table>
          <thead>
          {/* <tr className={"clickable-row"} onClick={(ev) => onSelectAllChecked(ev, !isAllSelected())}> */}
          <tr>
            <th scope="col" className={"index-col-head fixed-width"}>
              {/* <label htmlFor={"selectAll"}>
                <input type={"checkbox"} id={"selectAll"} name={"selectAll"}
                       title={"Select all"}
                       checked={isAllSelected()}
                       onChange={(ev) => onSelectAllChecked(ev, ev.target.checked)} />
              </label> */}
            </th>
            <th scope="col">Name</th>
            <th scope="col">Rank</th>
          </tr>
          </thead>
          <tbody>
          {isLoading && employeeBranchGroups &&
            <TableLoadingIndicator colspan={3} />
          }

          {!isLoading && employeeBranchGroups && employeeBranchGroups.length > 0 &&
            employeeBranchGroups.map((employeeBranch, index) =>
              <EmployeeTableRow key={employeeBranch['id']}
                                employeeBranch={employeeBranch}
                                isSelected={isSelected} onCheckChanged={onSelectChecked} />
            )
          }

          {!isLoading && employeeBranchGroups && employeeBranchGroups.length === 0 &&
            <TableEmptyRow colSpan={3} />
          }
          </tbody>
        </table>
      </div>

      <div className={"options-field-margin"}></div>
    </ModalContainer>
  )
}

const EmployeeTableRow = ({
                            employeeBranch,
                            isSelected, onCheckChanged,
                          }) => {
  return (
    <>
      <tr>
        <td className={"index-col"}></td>
        <td colSpan={2}>
          <b>{employeeBranch['name']}</b>
        </td>
      </tr>
      {employeeBranch['employees'].length > 0 && employeeBranch['employees'].map((employee, index) => 
        <tr className={"clickable-row"} key={employee['id']}
            onClick={(ev) => onCheckChanged(ev, employee['id'], !isSelected(employee['id']))}>
          <td className={"index-col"}>
            <label htmlFor={`select-business-type-${employee['id']}`}>
              <input type={"checkbox"} id={`select-business-type-${employee['id']}}`}
                    name={`select-business-type-${employee['id']}}`}
                    title={`Select ${employee['name']}`}
                    checked={isSelected(employee['id'])}
                    onChange={(ev) => onCheckChanged(ev, employee['id'], ev.target.checked)} />
            </label>
          </td>
          <td title={`Select ${employee['name']}`}>
            <label htmlFor={`select-business-type-${employee['id']}`} className={"no-padding"}>
              {employee["fullName"]}
            </label>
          </td>
          <td>
            {employee['rankName']}
          </td>
        </tr>
      )}
    </>
  )
}
