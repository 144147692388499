import axios from "axios";

let host = "http://localhost:8001/api";
// let host = "http://localhost:62241/api";
if (process.env.REACT_APP_ENV === "sit") {
  host = "/api";
} else if (process.env.REACT_APP_ENV === "staging") {
  host = "https://stage-ptimis2-38cdfed39666.herokuapp.com/api";
} else if (process.env.REACT_APP_ENV === "production") {
  host = "https://prod-ptimis2-02931404788a.herokuapp.com/api";
}

let portalHost = host + '/p';

const routes =  {
  "auth": {
  },
  "ref": {
    "businessTypes": `${host}/ref/business-types/`,
    "searchGenerators": `${host}/ref/generators/search/`,
    "searchGeneratorsOrATS": `${host}/ref/generators-ats/search/`,
    "regionsSearch": `${host}/ref/regions/search/`,
    "regionsList": `${host}/ref/regions/`,
    // "salesPersonsList": `${host}/ref/sales-persons/`,
    "employeesListByBranch": `${host}/ref/employees-by-branch/`,
  },
  "profiles": {
  },
  "sales": {
  },
  "inventory": {
    // ...
  },
  "technical": {
    "stockInspectionsSearch": `${host}/stock/stock-inspections-search/`,
    "stockInspectionsSummary": `${host}/stock/stock-inspections-summary/`,

    "serviceProfileCustomerSearch": `${host}/service/service-profile/customer-search/`,
    "serviceProfileGenerators": `${host}/service/service-profile-generators/`,
    "serviceProfileGenerator": `${host}/service/service-profile-generator/`,

    "jobHistorySearch": `${host}/service/job-history-search/`,
    "jobHistorySummary": `${host}/service/job-history-summary/`,
  },
  "customer": {
    "profileStatus": `${host}/customer/profile-status/`,
    "list": `${host}/customer/list/`,
    "careHistoryContactSubmit": `${host}/customer/care/history-contact/`,
    "careContactLogsSearch": `${host}/customer/care/contact-logs-search/`,
    "careContactLogsSummary": `${host}/customer/care/contact-logs-summary/`,
    "profileContactLogs": `${host}/customer/service-profile-contact-logs/`,
  },
  "portal": {
  },
  "report": {
    reportsList: `${host}/report/reports-list/`,

    // Technical/Service reports
    overWarrantyGenerators: { 
      fetch: `${host}/report/over-warranty-generators/`,
      submit: `${host}/report/over-warranty-generators/submit/`,
      generate: `${host}/report/over-warranty-generators/generate/`,
    },
    warrantyStatuses: {
      fetch: `${host}/report/warranty-statuses/`,
      submit: `${host}/report/warranty-statuses/submit/`,
      generate: `${host}/report/warranty-statuses/generate/`,
    },
    workDoneSummary: {
      fetch: `${host}/report/work-done-summary/`,
      submit: `${host}/report/work-done-summary/submit/`,
      generate: `${host}/report/work-done-summary/generate/`,
    },

  },
  "system": {
    "userAccountsList": `${host}/system/user-accounts/`,
    "userRolesList": `${host}/system/user-roles/`,
    "groupsList": `${host}/system/groups/`,

    "statesDivisionsList": `${host}/system/states-divisions/`,
    "businessTypesList": `${host}/system/business-types/`,
  },
}

const options = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
};

const getAuthenticatedOptions = () => {
  let token = localStorage.getItem('token');
  if (token == null) return options;
  let authOptions = options;
  authOptions.headers['Authorization'] = `Bearer ${token}`;
  return authOptions;
}

const getAuthenticatedBlobOptions = () => {
  let options = {
    'responseType': 'blob',
  };
  let token = localStorage.getItem('token');
  if (token == null) return options;
  options['headers'] = {
    'Authorization': `Bearer ${token}`,
  };
  return options;
}


export const authService = {}


export const refServices = {
  fetchBusinessTypes: function() {
    return axios.get(routes.ref.businessTypes, getAuthenticatedOptions());
  },

  searchGenerators: function(term) {
    return axios.get(routes.ref.searchGenerators + term, getAuthenticatedOptions());
  },

  searchGeneratorsOrATS: function(term) {
    return axios.get(routes.ref.searchGeneratorsOrATS + term, getAuthenticatedOptions());
  },

  searchRegions: function(term) {
    return axios.get(routes.ref.regionsSearch + "?term=" + term, getAuthenticatedOptions());
  },

  fetchAllRegions: function() {
    return axios.get(routes.ref.regionsList, getAuthenticatedOptions());
  },

  // NOTE(yemon): not used!
  fetchSalesPersonsList: function(payload) {
    //return axios.post(routes.ref.salesPersonsList, payload, getAuthenticatedOptions());
  },

  fetchEmployeesListByBranch: function(payload) {
    return axios.post(routes.ref.employeesListByBranch, payload, getAuthenticatedOptions());
  },
}


export const profilesServices = {}


export const salesServices = {}


// NOTE(yemon): The original 'inventory' module will be renamed to the more appropriate
// 'technical' as part of the API2 migration. Some routes will still be 'inventory' for
// backward compatibility purposes, but they will be revisited and slowly refactored out
// gradually later down the line.
export const inventoryServices = {
}

export const technicalServices = {
  searchStockInspections: function(payload) {
    return axios.post(routes.technical.stockInspectionsSearch, payload, getAuthenticatedOptions());
  },

  summarizeStockInspections: function(payload) {
    return axios.post(routes.technical.stockInspectionsSummary, payload, getAuthenticatedOptions());
  },

  // ...

  fetchServiceProfileGenerators: function(serviceProfileId) {
    return axios.get(routes.technical.serviceProfileGenerators + serviceProfileId, getAuthenticatedOptions());
  },

  fetchServiceProfileGenerator: function(params) {
    return axios.get(routes.technical.serviceProfileGenerator + params['service_generator_id'], getAuthenticatedOptions());
  },


  // ...

  searchJobHistories: function(payload) {
    return axios.post(routes.technical.jobHistorySearch, payload, getAuthenticatedOptions());
  },

  summarizeJobHistories: function(payload) {
    return axios.post(routes.technical.jobHistorySummary, payload, getAuthenticatedOptions());
  },
}


export const customerServices = {
  setProfileStatus: function(payload) {
    // return axios.post(routes.customer.profileStatus, payload, getAuthenticatedOptions());
  },

  fetchCustomerProfiles: function(tableOptions) {
    // return axios.post(routes.customer.list, tableOptions, getAuthenticatedOptions());
  },

  submitCareHistoryContact: function(payload) {
    // return axios.post(routes.customer.careHistoryContactSubmit, payload, getAuthenticatedOptions());
  },

  searchCareContactLogs: function(payload) {
    return axios.post(routes.customer.careContactLogsSearch, payload, getAuthenticatedOptions());
  },

  summarizeCareContactLogs: function(payload) {
    return axios.post(routes.customer.careContactLogsSummary, payload, getAuthenticatedOptions());
  },

  fetchServiceProfileContactLogs: function(serviceProfileId) {
    return axios.get(routes.customer.profileContactLogs + serviceProfileId, getAuthenticatedOptions());
  },
}


export const portalServices = {}

export const reportServices = {
  fetchReportsList: function(payload) {
    return axios.post(routes.report.reportsList, payload, getAuthenticatedOptions());
  },

  // Technical/Service reports
  overWarrantyGenerators: {
    fetch: function(reportId) {
      return axios.get(`${routes.report.overWarrantyGenerators.fetch}${reportId}`, getAuthenticatedOptions());
    },

    submit: function(payload) {
      return axios.post(routes.report.overWarrantyGenerators.submit, payload, getAuthenticatedOptions());
    },

    generate: function(payload) {
      return axios.post(routes.report.overWarrantyGenerators.generate, payload, getAuthenticatedOptions());
    },
  },
  warrantyStatuses: {
    fetch: function(reportId) {
      return axios.get(`${routes.report.warrantyStatuses.fetch}${reportId}`, getAuthenticatedOptions());
    },

    submit: function(payload) {
      return axios.post(routes.report.warrantyStatuses.submit, payload, getAuthenticatedOptions());
    },

    generate: function(payload) {
      return axios.post(routes.report.warrantyStatuses.generate, payload, getAuthenticatedOptions());
    },
  },
  workDoneSummary: {
    fetch: function(reportId) {
      return axios.get(`${routes.report.workDoneSummary.fetch}${reportId}`, getAuthenticatedOptions());
    },

    submit: function(payload) {
      return axios.post(routes.report.workDoneSummary.submit, payload, getAuthenticatedOptions());
    },

    generate: function(payload) {
      return axios.post(routes.report.workDoneSummary.generate, payload, getAuthenticatedOptions());
    },
  },

}

export const systemServices = {
  fetchUserAccounts: function() {
    return axios.get(routes.system.userAccountsList, getAuthenticatedOptions());
  },

  fetchUserRoles: function() {
    return axios.get(routes.system.userRolesList, getAuthenticatedOptions());
  },

  fetchGroups: function() {
    return axios.get(routes.system.groupsList, getAuthenticatedOptions());
  },

  fetchStatesDivisions: function() {
    return axios.get(routes.system.statesDivisionsList, getAuthenticatedOptions());
  },

  fetchBusinessTypes: function() {
    return axios.get(routes.system.businessTypesList, getAuthenticatedOptions());
  },
}
