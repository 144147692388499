import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { EMPTY_GUID } from "../../repos/constants";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { authService } from "../../repos/apiServices";
import { inventoryServices } from "../../repos/apiServices";
import { inventoryServices as inventoryServices2 } from "../../repos/apiServices2";
import { technicalServices } from "../../repos/apiServices2";
import { getListRowSerial } from "../../repos/utilities";
import { contexts } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";
import { getViewSettings } from "../../repos/viewContexts";
import { getPaginationContext } from "../../repos/viewContexts";
import { savePaginationContext } from "../../repos/viewContexts";
import { getSearchPanelOpenContext } from "../../repos/viewContexts";
import { saveSearchPanelOpenContext } from "../../repos/viewContexts";
import { getSearchPanelOptionsContext } from "../../repos/viewContexts";
import { saveSearchPanelOptionsContext } from "../../repos/viewContexts";
import { clearSearchPanelOptionsContext } from "../../repos/viewContexts";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { JobHistoriesListSearchPanel } from "./JobHistoriesListSearchPanel";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { NullBlankField } from "./NullBlankField";
import { SortIndicator } from "../shared/SortIndicator";
import { ActorPureNameDisplay } from "../sales/ActorNameDisplay";
import { JobStatusBadge } from "./JobStatusBadge";
import { JobHistoriesListSummaryModal } from "./JobHistoriesListSummaryModal";
import { jobHistorySummaryTypes } from "./JobHistoriesListSummaryModal";

import "../shared/DataTable.css";
import "../shared/ListingPage.css";
import "./JobHistoriesListPage.css";

// NOTE(yemon): Backward compatibility sake, especially when it comes to ISO datetime format.
// Need to remove when everything is migrated to API2.
const isUsingApi2 = true;

const serviceTypes = refs.inventory.serviceType;

export const JobHistoriesListPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [jobHistories, setJobHistories] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [servicePermissions, setServicePermissions] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    let paginationContext = getPaginationContext(contexts.jobHistories);
    let searchOptionsContext = loadSearchPanelContexts();
    if (searchOptionsContext !== null) {
      triggerSearchByContext(searchOptionsContext, paginationContext);
    }
    else {
      fetchJobHistories(paginationContext ? paginationContext['currentPage'] : 1);
    }
    //resetSearchPanelFields();
    fetchListPermissions();
  }, []);

  const fetchListPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _servicePermissions = response['data'];
        setServicePermissions(_servicePermissions);
      });
  }

  const prepareListPayload = (page) => {
    let settings = getViewSettings(viewSettings.jobHistories);
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        'page_size': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      }
    };
  }

  const fetchJobHistories = (page) => {
    resetListingStates();
    setIsLoading(true);
    let payload = prepareListPayload(page);

    technicalServices.searchJobHistories(payload)
      .then((response) => {
        updateListingStates(response['data']);

        // NOTE(yemon): resetting the summary type would automatically re-trigger the summary callback
        summaryModal.resetSummary();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const updateListingStates = (responseData) => {
    setJobHistories(responseData['data']);
    setPagination(responseData['pagination']);
    setSorting(responseData['sorting']);
    savePaginationContext(contexts.jobHistories, responseData['pagination']);
  }

  const resetListingStates = () => {
    setJobHistories([]);
    setPagination(null);
    setSorting(null);
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    resetSearchPanelFields();
    setIsSearchOpen(false);
    saveSearchPanelOpenContext(contexts.jobHistories, false);

    let paginationContext = getPaginationContext(contexts.jobHistories);
    fetchListPermissions();
    fetchJobHistories(paginationContext ? paginationContext['currentPage'] : 1);
  }

  const onRowClicked = (ev, jobHistory) => {
    let serviceProfileId = jobHistory['serviceProfileId'];
    let serviceGeneratorId = jobHistory['serviceGeneratorId'];
    let serviceHistoryId = jobHistory['serviceHistoryId'];

    let serviceType = jobHistory['serviceType'];

    switch (serviceType) {
      case serviceTypes.tnc:
        return navigateToServiceHistoryEntry(routes.testingAndCommission.url,
          serviceProfileId, serviceGeneratorId, serviceHistoryId);
      case serviceTypes.pm:
        return navigateToServiceHistoryEntry(routes.preventiveMaintenance.url,
          serviceProfileId, serviceGeneratorId, serviceHistoryId);
      case serviceTypes.regular:
        return navigateToServiceHistoryEntry(routes.regularService.url,
          serviceProfileId, serviceGeneratorId, serviceHistoryId);
      case serviceTypes.repair:
        return navigateToServiceHistoryEntry(routes.repairService.url,
          serviceProfileId, serviceGeneratorId, serviceHistoryId);
      case serviceTypes.emergency:
        return navigateToServiceHistoryEntry(routes.emergencyBreakdown.url,
          serviceProfileId, serviceGeneratorId, serviceHistoryId);
      case serviceTypes.inspection:
        return navigateToServiceHistoryEntry(routes.inspection.url,
          serviceProfileId, serviceGeneratorId, serviceHistoryId);
      default:
        break;
    }
  }

  const navigateToServiceHistoryEntry = (url, serviceProfileId, serviceGeneratorId, serviceHistoryId) => {
    setTimeout(() => {
      navigate(url, {
        state: {
          'serviceProfileId': serviceProfileId,
          'serviceGeneratorId': serviceGeneratorId,
          'serviceHistoryId': serviceHistoryId,
        }
      });
    }, 200);
  }

  const onRowCustomerClicked = (ev, jobHistory) => {
    let serviceProfileId = jobHistory['serviceProfileId'];
    let customerId = jobHistory['customerId'];

    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onRowGeneratorClicked = (ev, jobHistory) => {
    let serviceGeneratorId = jobHistory['serviceGeneratorId'];
    let serviceProfileId = jobHistory['serviceProfileId'];
    let customerId = jobHistory['customerId'];

    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'generatorId': serviceGeneratorId,
          'profileId': serviceProfileId,
          'customerId': customerId,
        }
      })
    }, 200);
  }

  const onPageClick = (page) => {
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchJobHistories(page);
    }
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchJobHistories(page);
    }
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchJobHistories(page);
    }
  }
  //#endregion

  //#region States; Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [searchFields, setSearchFields] = useState({});
  const [searchOptions, setSearchOptions] = useState(null);
  const [gensetModelSearchTerm, setGensetModelSearchTerm] = useState('');

  const [hasSearchErrors, setHasSearchErrors] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  //#endregion

  //#region Control handlers; Search
  const onSearchToggleClicked = () => {
    let _isOpen = !isSearchOpen;
    setIsSearchOpen(_isOpen);
    saveSearchPanelOpenContext(contexts.jobHistories, _isOpen);
  }

  const validateSearchDateRanges = () => {
    let validation = {
      hasErrors: false,
      errorMessage: '',
    };

    let _fromDate = searchFields['serviced_date_from'];
    let _toDate = searchFields['serviced_date_to'];
    if (_fromDate && _toDate) {
      if (_toDate < _fromDate) {
        validation.hasErrors = true;
        validation.errorMessage = 'Invalid date range.';
      }
    }
    if (validation.hasErrors) {
      return validation;
    }

    if (_fromDate && !_toDate) {
      _toDate = new Date();
    }
    if (!_fromDate && _toDate) {
      // set the first day of the current 'to date' month
      _fromDate = new Date(_toDate.getFullYear(), _toDate.getMonth(), 1);
    }
    validation.fromDate = _fromDate;
    validation.toDate = _toDate;

    return validation;
  }

  const validateAndPrepareSearchOptions = () => {
    let dateValidation = validateSearchDateRanges();
    if (dateValidation.hasErrors) {
      setHasSearchErrors(true);
      setSearchErrorMessage(dateValidation.errorMessage);
      return null;
    }

    let searchOptions = {
      ...searchFields,
    };

    if (!dateValidation.hasErrors && (dateValidation.fromDate && dateValidation.toDate)) {
      setSearchFields({
        ...searchFields,
        'serviced_date_from': dateValidation.fromDate,
        'serviced_date_to': dateValidation.toDate,
      });
      if (isUsingApi2) {
        searchOptions['serviced_date_from'] = dateValidation.fromDate.toISOString();
        searchOptions['serviced_date_to'] = dateValidation.toDate.toISOString();
      }
      else {
        searchOptions['serviced_date_from'] = dateValidation.fromDate.toLocaleString();
        searchOptions['serviced_date_to'] = dateValidation.toDate.toLocaleString();
      }
    }

    return searchOptions;
  }

  const validateAndTriggerSearch = (page) => {
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    let listPayload = prepareListPayload(page);
    let searchOptions = validateAndPrepareSearchOptions();
    if (searchOptions === null) return;
    listPayload['is_searching'] = true;
    listPayload['search_options'] = searchOptions;
    setSearchOptions(searchOptions);

    let searchOptionsContext = {
      ...searchOptions,
      'genset_model_term': gensetModelSearchTerm,
    };
    saveSearchPanelOptionsContext(contexts.jobHistories, searchOptionsContext);

    triggerSearch(listPayload);
  }

  const triggerSearchByContext = (searchOptionsContext, paginationContext) => {
    let listPayload = {
      ...prepareListPayload(1),   // page number here doesn't matter, will be overridden by the pagination context below
      'is_searching': true,
      'search_options': searchOptionsContext,
      'pagination': {
        'current_page': paginationContext ? paginationContext['currentPage'] : 1,
        'page_size': paginationContext ? paginationContext['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      },
    };
    triggerSearch(listPayload);
  }

  const triggerSearch = (listPayload) => {
    resetListingStates();
    setIsSearchLoading(true);

    technicalServices.searchJobHistories(listPayload)
      .then((response) => {
        setIsSearching(true);
        updateListingStates(response['data']);

        summaryModal.fetchSummary(summaryType, true, listPayload['search_options']);
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  }

  const onSearchButtonClicked = (ev) => {
    validateAndTriggerSearch(1);
  }

  const onClearButtonClicked = (ev) => {
    clearSearchPanelOptionsContext(contexts.jobHistories);
    fetchJobHistories(1);
    resetSearchPanelFields();
  }

  const resetSearchPanelFields = () => {
    setIsSearching(false);
    setSearchOptions(null);
    setSearchFields({
      'serviced_date_from': null,
      'serviced_date_to': null,
      'serviced_by_id': '',
      'customer_name': '',
      'generator_serial': '',
      'genset_model_id': '',
      'service_type': -1,
      'job_status': -1,
    });
    setGensetModelSearchTerm('');
    setHasSearchErrors(false);
    setSearchErrorMessage('');
  }

  const onServicedDateFromSearchChanged = (fromDate) => {
    setSearchFields({
      ...searchFields,
      'serviced_date_from': fromDate,
    });
  }

  const onServicedDateToSearchChanged = (toDate) => {
    setSearchFields({
      ...searchFields,
      'serviced_date_to': toDate,
    });
  }

  const onServicedByChanged = (employeeId) => {
    setSearchFields({
      ...searchFields,
      'serviced_by_id': employeeId,
    });
  }

  const onCustomerNameSearchChanged = (ev) => {
    setSearchFields({
      ...searchFields,
      'customer_name': ev.target.value,
    });
  }

  const onGeneratorSerialSearchChanged = (ev) => {
    setSearchFields({
      ...searchFields,
      'generator_serial': ev.target.value,
    });
  }

  const onGensetModelTermSearchChanged = (ev, gensetModelTerm, prevGensetModelTerm) => {
    if (gensetModelTerm.trim() === '') {
      setGensetModelSearchTerm('');
      setSearchFields({
        ...searchFields,
        'genset_model_id': '',
      });
      return false;
    }
    if (gensetModelTerm === prevGensetModelTerm) {
      return false;
    }
    setGensetModelSearchTerm(gensetModelTerm);
    setSearchFields({
      ...searchFields,
      'genset_model_id': '',
    });
    return true;
  }

  const onGensetModelSuggestionClicked = (ev, generator) => {
    setGensetModelSearchTerm(generator['gensetModel']);
    setSearchFields({
      ...searchFields,
      'genset_model_id': generator['id'],
    });
  }

  const onServiceTypeChanged = (serviceType) => {
    setSearchFields({
      ...searchFields,
      'service_type': parseInt(serviceType),
    });
  }

  const onJobStatusChanged = (jobStatus) => {
    setSearchFields({
      ...searchFields,
      'job_status': parseInt(jobStatus),
    });
  }
  //#endregion

  //#region Quick Summaries modal;
  const [isSummaryModalOpened, setIsSummaryModalOpened] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [summaryType, setSummaryType] = useState(jobHistorySummaryTypes.serviceType);
  const [summaryData, setSummaryData] = useState([]);
  const [summaryTotal, setSummaryTotal] = useState(0);

  useEffect(() => {
    // NOTE(yemon): Without this small delay, the parent listing page's state update (particularly on
    // `summaryData` and `summaryTotal`) just couldn't propagate down to the summary modal in time,
    // for whatever freaking reason, resulting in the modal showing no summary data, even though the API
    // call was successful without a hitch.
    // What's even weirder is, the 'care contacts log summary' modal, which works exactly the same as this
    // one, had absolutely no problem or the need to have this delay.
    setTimeout(() => {
      summaryModal.fetchSummary(summaryType, isSearching, searchOptions);
    }, 50);
  }, [summaryType]);

  const summaryModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsSummaryModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsSummaryModalOpened(false);
      }, 200);
    },

    fetchSummary: function(type, isSearching = false, searchOptions = null) {
      let payload = {
        'uid': auth.getUserId(),
        'type': type,
        'isSearching': isSearching,
        'searchOptions': searchOptions,
      };

      setIsSummaryLoading(true);
      technicalServices.summarizeJobHistories(payload)
        .then((response) => {
          const responseData = response['data'];
          setTimeout(() => {
            setSummaryData(responseData['data']);
            setSummaryTotal(responseData['total']);
          }, 500);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setIsSummaryLoading(false));
    },

    resetSummary: function() {
      setSummaryType(jobHistorySummaryTypes.serviceType);
      setSummaryData([]);
      setSummaryTotal(0);
    },
  }
  //#endregion

  //#region View Settings modal; Handlers and other utilities
  const onViewSettingsSaved = () => {
    if (isSearching) {
      validateAndTriggerSearch(1);
    }
    else {
      fetchJobHistories(1);
    }
  }
  //#endregion

  //#region Listing view context
  const loadSearchPanelContexts = () => {
    let isSearchPanelOpen = getSearchPanelOpenContext(contexts.jobHistories);
    setIsSearchOpen(isSearchPanelOpen);

    let searchOptions = getSearchPanelOptionsContext(contexts.jobHistories);
    if (searchOptions === null) {
      return null;
    }

    setSearchFields({
      'serviced_date_from': searchOptions['serviced_date_from'] ? new Date(searchOptions['serviced_date_from']) : null,
      'serviced_date_to': searchOptions['serviced_date_to'] ? new Date(searchOptions['serviced_date_to']) : null,
      'serviced_by_id': searchOptions['serviced_by_id'],
      'customer_name': searchOptions['customer_name'],
      'generator_serial': searchOptions['generator_serial'],
      'genset_model_id': searchOptions['genset_model_id'],
      'service_type': searchOptions['service_type'],
      'job_status': searchOptions['job_status'],
    });
    setGensetModelSearchTerm(searchOptions['genset_model_term']);

    return searchOptions;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container job-histories-list-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.jobHistories.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.jobHistories.display}</h1>

            <div className={"listing-controls"}>
              <div className={"left"}>
                <button className={"btn btn-secondary"} disabled={isLoading || isSearchLoading}
                        onClick={onRefreshClicked}>
                  {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isLoading && <i className="fa-solid fa-rotate"></i>}
                  Refresh
                </button>

                <button type={"button"} className={"btn btn-secondary search-toggle-button"} disabled={isLoading}
                        onClick={onSearchToggleClicked}>
                  {isSearchOpen && <i className="fa-solid fa-magnifying-glass-minus"></i>}
                  {!isSearchOpen && <i className="fa-solid fa-magnifying-glass-plus"></i>}
                  <span>Search</span>
                </button>
              </div>

              <div className={"right"}>
                <button type={"button"} className={"btn btn-secondary"} disabled={isLoading}
                        onClick={summaryModal.onOpenButtonClicked}>
                  <i className={"fa-regular fa-rectangle-list"}></i>
                  <span>Quick Summary...</span>
                </button>
              </div>
            </div>
          </div>

          <JobHistoriesListSummaryModal isOpen={isSummaryModalOpened} onRequestClose={summaryModal.onCloseButtonClicked}
                                        isSearching={isSearching} searchFields={searchFields}
                                        isSummaryLoading={isSummaryLoading}
                                        summaryType={summaryType} setSummaryType={setSummaryType}
                                        data={summaryData} total={summaryTotal} />

          <JobHistoriesListSearchPanel isSearchOpen={isSearchOpen} isSearchLoading={isSearchLoading} isListLoading={isLoading}
                                       searchFields={searchFields}
                                       onServicedDateFromChanged={onServicedDateFromSearchChanged}
                                       onServicedDateToChanged={onServicedDateToSearchChanged}
                                       onServicedByChanged={onServicedByChanged}
                                       onCustomerNameChanged={onCustomerNameSearchChanged}
                                       onGeneratorSerialChanged={onGeneratorSerialSearchChanged}
                                       gensetModelTerm={gensetModelSearchTerm}
                                       onGensetModelTermChanged={onGensetModelTermSearchChanged}
                                       onGensetModelSuggestionClicked={onGensetModelSuggestionClicked}
                                       onServiceTypeChanged={onServiceTypeChanged}
                                       onJobStatusChanged={onJobStatusChanged}
                                       onSearchClicked={onSearchButtonClicked} onClearClicked={onClearButtonClicked}
                                       hasErrors={hasSearchErrors} errorMessage={searchErrorMessage} />

          <div className={"data-table"}>
            <table>
              <thead>
              <JobHistoriesTableHeading />
              </thead>
              <tbody>
              {isLoading && <TableLoadingIndicator colspan={7} />}

              {jobHistories && jobHistories.length > 0 && !isLoading &&
                jobHistories.map((jobHistory, index) =>
                  <JobHistoriesTableRow key={jobHistory['serviceHistoryId']} jobHistory={jobHistory} index={index}
                                        currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                        onRowClicked={onRowClicked} onRowCustomerClicked={onRowCustomerClicked}
                                        onRowGeneratorClicked={onRowGeneratorClicked} />
                )
              }

              {!jobHistories || (jobHistories.length === 0 && !isLoading &&
                  <TableEmptyRow colSpan={7} />
              )}
              </tbody>
            </table>
            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClick}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
                               viewSettingsNamespace={viewSettings.jobHistories}
                               onViewSettingsSaved={onViewSettingsSaved} />
            }
          </div>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const JobHistoriesTableHeading = () => {
  return (
    <tr>
      <th scope={"col"} className={"index-col-head"}>#</th>
      <th scope={"col"}>
        Serviced Date
        <SortIndicator isAscending={false} />
      </th>
      <th scope={"col"}>Serviced By / Branch</th>
      <th scope={"col"}>Customer</th>
      <th scope={"col"}>Generator Serial / Genset Model</th>
      <th scope={"col"}>Service Type</th>
      <th scope={"col"}>Status</th>
    </tr>
  )
}

const JobHistoriesTableRow = ({
                                jobHistory, index,
                                currentPage, pageSize,
                                onRowClicked, onRowCustomerClicked, onRowGeneratorClicked,
                              }) => {
  return (
    <tr>
      <td className={"index-col"}>{getListRowSerial(pageSize, currentPage, index)}</td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           title={`View the ${serviceTypes[jobHistory['serviceType']]} service history entry`}
           onClick={(ev) => onRowClicked(ev, jobHistory)}>
          <Moment date={jobHistory['servicedDatetime']} format={formatters.datetimeShort} />
        </a>
      </td>
      <td>
        <ActorPureNameDisplay employeeId={jobHistory['servicedById']}
                              employeeName={jobHistory['servicedByFullName']} />
        {jobHistory['servicedById'] !== EMPTY_GUID &&
          <>&nbsp;/&nbsp;
            <span title={"Branch"}>
              {jobHistory['servicedByBranchName'] ? jobHistory['servicedByBranchName'] : '-'}
            </span>
          </>
        }
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           title={"View the customer's service profile"}
           onClick={(ev) => onRowCustomerClicked(ev, jobHistory)}>
          {jobHistory['customerName']}
        </a>
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           title={"View the service generator"}
           onClick={(ev) => onRowGeneratorClicked(ev, jobHistory)}>
          <span title={"Generator serial"}>{jobHistory['generatorSerial']}</span>&nbsp;/&nbsp;
          <span title={"Genset model"}>{jobHistory['gensetModel']}</span>
        </a>
      </td>
      <td>
        {serviceTypes[jobHistory['serviceType']]}
      </td>
      <td>
        <JobStatusBadge status={jobHistory['jobStatus']} />
      </td>
    </tr>
  )
}
