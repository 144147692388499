import { refs } from "./constants";

export const formatCurrency = (amount) => {
  if (amount === null || amount === 0) return '0';
  // noinspection JSCheckFunctionSignatures
  return amount.toLocaleString('en-US', { minimumFractionDigits: 2 });
}

export const sanitizeCurrencyValue = (price) => {
  let fValue = parseFloat(price);
  if (isNaN(fValue) || !isFinite(fValue)) {
    fValue = 0;
  }
  return fValue.toFixed(2);
}

export const getCurrency = (currencyCode) => {
  let currency = {};
  for (let [key, obj] of Object.entries(refs.currency)) {
    if (obj.code === currencyCode) {
      currency = obj;
      break;
    }
  }
  return currency;
}

export const getCurrencyDisplay = (currencyCode) => {
  let currency = getCurrency(currencyCode);
  return `${currency.name} (${currency.sign})`;
}

export const getSourceDisplay = (sourceId) => {
  let sourceRef = refs.sales.source;
  if (!sourceRef) return '';
  return sourceRef[sourceId]['name'];
}

export const formatSourceName = (sourceId) => {
  let sourceRef = refs.sales.source;
  if (!sourceRef) return '';
  return `[${sourceRef[sourceId]['code']}] ${sourceRef[sourceId]['name']}`;
}

export const formatSourceDescription = (sourceId) => {
  let sourceRef = refs.sales.source;
  if (!sourceRef) return '';
  return `[${sourceRef[sourceId]['code']}] ${sourceRef[sourceId]['description']}`;
}

export const getFollowUpActionDisplay = (actionId) => {
  let followUpStages = refs.sales.followUpStages;
  return followUpStages.find(stage => stage['id'] === actionId)['name'];
}

export const getFollowUpStageActionDisplay = (stageId, actionId) => {
  let followUpStages = refs.sales.followUpStages;
  let stage = followUpStages.find(stage => stage['id'] === stageId);
  let displayText = stage['name'];
  let action = stage['actions'].find(action => action['id'] === actionId);
  if (action !== undefined) {
    displayText += " > " + action['name'];
  }
  return displayText;
}

export const getFollowUpStage = (stageId) => {
  let followUpStages = refs.sales.followUpStages;
  return followUpStages.find(stage => stage['id'] === stageId);
}

export const getInstallmentOptionDisplay = (optionId) => {
  let installmentOptions = refs.sales.paymentInstallmentOptions;
  return installmentOptions.find(option => option['id'] === optionId).name;
}

export const getGeneratorTypeDisplay = (typeValue) => {
  return refs.inventory.generatorType[typeValue];
}

export const getStatusDisplay = (statusValue) => {
  return refs.sales.activeStatus[statusValue];
}

export const getRegionDisplay = (city, township) => {
  if (township !== null) {
    return `${township.cityName} / ${township.name}`;
  }
  if (city !== null) {
    return `${city.name}`;
  }
  return '';
}

export const getRegionDisplayByName = (cityName, townshipName) => {
  if (townshipName !== null) {
    return `${cityName} / ${townshipName}`;
  }
  if (cityName !== null) {
    return `${cityName}`;
  }
  return '';
}

export const formatTownshipDisplay = (township) => {
  if (!township) return '';
  return `${township['cityName']} / ${township['name']}`;
}

export const isEmployeeAssignedToInquiry = (employeeId, inquiryReporters) => {
  if (!inquiryReporters || inquiryReporters.length === 0) return false;
  for (let assignment of inquiryReporters) {
    if (assignment['salesPerson']['id'] === employeeId) return true;
  }
  return false;
}

export const getDisplayTermFromGeneratorDetail = (generatorDetail) => {
  let _term = '';
  if (generatorDetail['gensetModel']) _term = generatorDetail['gensetModel'];
  else if (generatorDetail['atsName']) _term = generatorDetail['atsName'];
  else _term = '(NA)';
  return _term;
}

export const calculatePoEstimationDate = (poDuration) => {
  let _poDate = new Date();
  switch (poDuration) {
    case refs.sales.poDurations.threeMonths:
      _poDate.setMonth(_poDate.getMonth() + 3);
      return _poDate;
    case refs.sales.poDurations.sixMonths:
      _poDate.setMonth(_poDate.getMonth() + 6);
      return _poDate;
    case refs.sales.poDurations.oneYear:
      _poDate.setMonth(_poDate.getMonth() + 12);
      return _poDate;
  }
}

export const getManufacturerDisplay = (manufacturer) => {
  return refs.inventory.generatorManufacturers[manufacturer];
}

export const getAlternatorDisplay = (alternator) => {
  if (!alternator) return '';
  return refs.inventory.generatorAlternators[alternator];
}

export const getMachineDisplay = (machine) => {
  if (!machine) return '';
  return refs.inventory.generatorMachines[machine];
}

export const getControllerDisplay = (controller) => {
  if (!controller) return '';
  return refs.inventory.generatorControllers[controller];
}

export const clamp = (num, min, max) => {
  // e.g.,
  //  clamp(-50, 0, 100) = 0
  //  clamp(50,  0, 100) = 50
  //  clamp(120, 0, 100) = 100
  // NOTE(yemon): Alternatively, this function can be injected into Number.prototype,
  // but NO! I don't like playing with JS prototypes.
  return Math.min(Math.max(num, min), max);
}

export const roundFloat = (floatNum, fractions) => {
  if (floatNum === null) return '';
  if (String(floatNum).trim() === '') return '';
  const _floatNum = parseFloat(floatNum);
  return _floatNum.toFixed(fractions);
}

export const getListRowSerial = (pageSize, currentPage, index) => {
  return (pageSize * (currentPage - 1)) + (index + 1);
}
