import React from "react";
import { useContext } from "react";
import { createContext } from "react";
import { useState } from "react";
import { useEffect } from "react";

import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { roundFloat } from "../../repos/utilities";
import { AutoCompleteResults } from "../shared/AutoCompleteResults";

import { ServiceEntryHeader } from "./ServiceEntryHeader";
import { ServiceEntryArrivalTestDetails } from "./ServiceEntryArrivalTestDetails";
import { ServiceEntryPredeliveryTestDetails } from "./ServiceEntryPredeliveryTestDetails";
import { ServiceEntryTestResults } from "./ServiceEntryTestResults";
import { ServiceEntryAdditionalInfo } from "./ServiceEntryAdditionalInfo";
import { ServiceEntrySignature } from "./ServiceEntrySignature";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "./NullBlankField";
import { InfoMessage } from "../sales/FormMessages";

import './ArrivalInspectionEntryPage.css';
import '../shared/EntryForm.css';


export const inspectionFormMode = {
  arrivalInspection: 0,
  predeliveryInspection: 1,
  monthly: 2,
}

const InspectionFormContext = createContext(null);

const stockInspectionTypes = refs.inventory.stockInspectionType;
const testModes = refs.inventory.testMode;
const governorTypes = refs.inventory.governorType;
const stockStatuses = refs.inventory.stockStatus;

const generatorManufacturers = refs.inventory.generatorManufacturers;
const generatorAlternators = refs.inventory.generatorAlternators;
const generatorMachines = refs.inventory.generatorMachines;
const generatorControllers = refs.inventory.generatorControllers;

/// Basically a state handler for two different inspection forms;
/// Arrival Inspection and Pre-Delivery Inspection
export const InspectionFormProvider = ({ children }) => {
  //#region States
  const [formMode, setFormMode] = useState(inspectionFormMode.arrivalInspection);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const [isEditableGenerator, setIsEditableGenerator] = useState(false);
  //#endregion

  //#region Effects
  useEffect(() => {
  }, []);

  const prepareEntryForEditMode = (stock, inspection) => {
    let inspectionDetails = inspection['details'];

    {
      // Header
      setStandbyPower(stock['powerStandby'] ?? '');
      setProductYear(stock['productYear'] ?? '');
      let _entryDate = new Date(inspection['inspectionDate']);
      setEntryDate(_entryDate);
      setInspectionType(inspection['inspectionType']);
      setTestMode(inspection['testingMode']);
    }

    {
      // Test details
      setRunHours(inspectionDetails['runHours'] ?? 0);
      setRunHoursMin(inspectionDetails['runHoursMin'] ?? 0);
      setEngineRPM(inspectionDetails['engineRPM'] ?? '');
      setFuel(inspectionDetails['fuel'] ?? '');
      setOilPressure(inspectionDetails['oilPressure'] ?? '');
      setWaterTemperature(inspectionDetails['waterTemperature'] ?? '');

      if (formMode === inspectionFormMode.arrivalInspection) {
        setEngineOil(inspectionDetails['engineOil'] ?? '');
        setCoolantWater(inspectionDetails['coolantWater'] ?? '');
        setTestingPeriodMin(inspectionDetails['testingPeriodMin'] ?? 0);
      }
      if (formMode === inspectionFormMode.predeliveryInspection) {
        setL1N(inspectionDetails['l1n'] ?? '');
        setL2N(inspectionDetails['l2n'] ?? '');
        setL3N(inspectionDetails['l3n'] ?? '');
      }

      setDCVoltage(inspectionDetails['dcVoltage'] ?? '');
    }

    {
      // Test Results
      setCheckEngineOil(inspectionDetails['checkEngineOil'] ?? false);
      setCheckCoolant(inspectionDetails['checkCoolant'] ?? false);
      setCheckDriveBeltTension(inspectionDetails['checkDriveBeltTension'] ?? false);
      setCheckBatteriesCondition(inspectionDetails['checkBatteriesCondition'] ?? false);
      setCheckWiringConnection(inspectionDetails['checkWiringConnection'] ?? false);
      setCheckSwitchNCircuitBreaker(inspectionDetails['checkSwitchNCircuitBreaker'] ?? false);
      setCheckOilCoolantFuelLeaks(inspectionDetails['checkOilCoolantFuelLeaks'] ?? false);
      setCheckHosesPipesClamps(inspectionDetails['checkHosesPipesClamps'] ?? false);
      setCheckSensors(inspectionDetails['checkSensors'] ?? false);
      setCheckTightenHoldingDownBolts(inspectionDetails['checkTightenHoldingDownBolts'] ?? false);
      setCheckFuelDrainPlugSeal(inspectionDetails['checkFuelDrainPlugSeal'] ?? false);
      setCheckAKSAToolsKit(inspectionDetails['checkAKSAToolsKit'] ?? false);
      setCheckDrawingNManualBooks(inspectionDetails['checkDrawingNManualBooks'] ?? false);
      setCheckRadiatorHeadCover(inspectionDetails['checkRadiatorHeadCover'] ?? false);
      setCheckStickers(inspectionDetails['checkStickers'] ?? false);
      setCheckDoorsPanelKeys(inspectionDetails['checkDoorsPanelKeys'] ?? false);

      if (formMode === inspectionFormMode.arrivalInspection) {
        setL1N(inspectionDetails['l1n'] ?? '');
        setL2N(inspectionDetails['l2n'] ?? '');
        setL3N(inspectionDetails['l3n'] ?? '');
        setL1L2(inspectionDetails['l1l2'] ?? '');
        setL2L3(inspectionDetails['l2l3'] ?? '');
        setL3L1(inspectionDetails['l3l1'] ?? '');
        setFrequency(inspectionDetails['frequency'] ?? '');
        setAVRType(inspectionDetails['avrType'] ?? '');
        setGovernorType(inspectionDetails['governorType'] ?? '');
      }
      if (formMode === inspectionFormMode.predeliveryInspection) {
        setCheckGensetHousekeeping(inspectionDetails['checkGensetHousekeeping'] ?? false);
      }
    }

    {
      // Additional info
      setRemarks(inspectionDetails['remarks'] ?? '');
      setDisconnectBatteryNegative(inspectionDetails['disconnectBatteryNegative'] ?? '');
      setConnectFuelSectionReturnPipe(inspectionDetails['connectFuelSectionReturnPipe'] ?? '');
    }

    {
      // Signatures
      setJobStatus(inspection['status']);
      setTestedBy(inspection['testedBy']);
      if (inspection['checkedBy']) {
        setCheckedBy(inspection['checkedBy']);
      }
      if (inspection['checkedDate']) {
        setCheckedDate(inspection['checkedDate']);
      }
    }

    setIsLoading(false);
  }

  const prepareGeneratorForEditMode = (generator) => {
    setGeneratorId(generator['id']);
    setGensetModelTermSearch(generator['gensetModel']);
    setGeneratorSerial(generator['generatorSerial']);
    setGenerator(generator['manufacturer']);
    setAlternatorSerial(generator['alternatorSerial']);
    setAlternatorModel(generator['alternatorModel']);
    setAlternator(generator['alternator']);
    setMachineSerial(generator['machineSerial']);
    setMachineModel(generator['machineModel']);
    setMachine(generator['machine']);
    setControllerSerial(generator['controllerSerial']);
    setControllerModel(generator['controllerModel']);
    setController(generator['controller']);
  }
  //#endregion

  //#region States; Header section
  const [standbyPower, setStandbyPower] = useState("");
  const [productYear, setProductYear] = useState("");
  const [entryDate, setEntryDate] = useState(new Date());
  const [minEntryDate, setMinEntryDate] = useState(null);
  const [inspectionType, setInspectionType] = useState(stockInspectionTypes.arrival);
  const [testMode, setTestMode] = useState(testModes.onLoad);
  const [hasHeaderErrors, setHasHeaderErrors] = useState(false);
  const [headerErrors, setHeaderErrors] = useState({});

  const [inspectionTypeTitle, setInspectionTypeTitle] = useState("");

  useEffect(() => {
    setInspectionTypeTitle(stockInspectionTypes[inspectionType]);
  }, [inspectionType]);
  //#endregion

  //#region States; Generator Details section
  const [generatorId, setGeneratorId] = useState(null);
  const [gensetModelTermSearch, setGensetModelTermSearch] = useState('');

  const [gensetModel, setGensetModel] = useState("");
  const [generatorSerial, setGeneratorSerial] = useState("");
  const [generator, setGenerator] = useState(generatorManufacturers.AKSA);
  const [alternatorSerial, setAlternatorSerial] = useState("");
  const [alternatorModel, setAlternatorModel] = useState("");
  const [alternator, setAlternator] = useState(generatorAlternators.unspecified);
  const [machineSerial, setMachineSerial] = useState("");
  const [machineModel, setMachineModel] = useState("");
  const [machine, setMachine] = useState(generatorMachines.unspecified);
  const [controllerSerial, setControllerSerial] = useState("");
  const [controllerModel, setControllerModel] = useState("");
  const [controller, setController] = useState(generatorControllers.unspecified);

  const [hasGeneratorErrors, setHasGeneratorErrors] = useState(false);
  const [generatorErrors, setGeneratorErrors] = useState({});
  //#endregionn

  //#region States; Test Details sections
  const [engineOil, setEngineOil] = useState("");
  const [coolantWater, setCoolantWater] = useState("");
  const [fuel, setFuel] = useState("");
  const [runHours, setRunHours] = useState(0);
  const [runHoursMin, setRunHoursMin] = useState(0);
  const [oilPressure, setOilPressure] = useState("");
  const [waterTemperature, setWaterTemperature] = useState("");
  const [engineRPM, setEngineRPM] = useState("");
  const [testingPeriodMin, setTestingPeriodMin] = useState(0);
  const [dcVoltage, setDCVoltage] = useState("");
  //#endregion

  //#region States; Test Results section
  const [l1n, setL1N] = useState("");
  const [l2n, setL2N] = useState("");
  const [l3n, setL3N] = useState("");
  const [l1l2, setL1L2] = useState("");
  const [l2l3, setL2L3] = useState("");
  const [l3l1, setL3L1] = useState("");
  const [frequency, setFrequency] = useState("");
  const [avrType, setAVRType] = useState("");
  const [governorType, setGovernorType] = useState(governorTypes.electronic);

  const [checkEngineOil, setCheckEngineOil] = useState(false);
  const [checkCoolant, setCheckCoolant] = useState(false);
  const [checkDriveBeltTension, setCheckDriveBeltTension] = useState(false);
  const [checkBatteriesCondition, setCheckBatteriesCondition] = useState(false);
  const [checkWiringConnection, setCheckWiringConnection] = useState(false);
  const [checkSwitchNCircuitBreaker, setCheckSwitchNCircuitBreaker] = useState(false);
  const [checkOilCoolantFuelLeaks, setCheckOilCoolantFuelLeaks] = useState(false);
  const [checkHosesPipesClamps, setCheckHosesPipesClamps] = useState(false);
  const [checkGensetHousekeeping, setCheckGensetHousekeeping] = useState(false);
  const [checkSensors, setCheckSensors] = useState(false);
  const [checkTightenHoldingDownBolts, setCheckTightenHoldingDownBolts] = useState(false);
  const [checkFuelDrainPlugSeal, setCheckFuelDrainPlugSeal] = useState(false);
  const [checkAKSAToolsKit, setCheckAKSAToolsKit] = useState(false);
  const [checkDrawingNManualBooks, setCheckDrawingNManualBooks] = useState(false);
  const [checkRadiatorHeadCover, setCheckRadiatorHeadCover] = useState(false);
  const [checkStickers, setCheckStickers] = useState(false);
  const [checkDoorsPanelKeys, setCheckDoorsPanelKeys] = useState(false);
  //#endregion

  //#region States; Additional Info
  const [remarks, setRemarks] = useState("");
  const [disconnectBatteryNegative, setDisconnectBatteryNegative] = useState(false);
  const [connectFuelSectionReturnPipe, setConnectFuelSectionReturnPipe] = useState(false);

  const [jobStatus, setJobStatus] = useState(stockStatuses.draft);
  const [testedBy, setTestedBy] = useState({});
  const [checkedBy, setCheckedBy] = useState(null);
  const [checkedDate, setCheckedDate] = useState(null);
  //#endregion

  //#region Control handlers; Header section
  const isHeaderFieldsValid = () => {
    let _hasErrors = false;
    let _headerErrors = {
      entryDate: [],
    };

    if (!entryDate) {
      _hasErrors = true;
      _headerErrors.entryDate.push("Form entry date is required.");
    }

    setHasHeaderErrors(_hasErrors);
    setHeaderErrors(_headerErrors);
    return _hasErrors;
  }

  const prepareHeaderPayload = () => {
    return {
      'power_standby': standbyPower !== '' ? standbyPower : null,
      'product_year': productYear !== '' ? productYear : null,
      'entry_date': entryDate,
      'inspection_type': inspectionType,
      'test_mode': testMode,
    }
  }

  const resetHeaderFields = () => {
    setStandbyPower("");
    setProductYear("");
    setEntryDate(new Date());
    setInspectionType(stockInspectionTypes.predelivery);
    setTestMode(testModes.onLoad);
  }

  const onStandbyPowerChanged = (ev) => {
    setStandbyPower(ev.target.value);
  }

  const onProductYearChanged = (ev) => {
    setProductYear(ev.target.value);
  }

  const onEntryDateChanged = (date) => {
    setEntryDate(date);
  }

  const onInspectionTypeChanged = (ev) => {
    setInspectionType(parseInt(ev.target.value));
  }

  const onTestModeChanged = (ev) => {
    setTestMode(parseInt(ev.target.value));
  }
  //#endregion

  //#region Control handlers; Generator Details section
  const isGeneratorDetailsFieldValid = () => {
    let _hasErrors = false;
    let _generatorDetailsErrors = {
      gensetModel: [],
      generatorSerial: [],
    };

    if (generatorId === null) {
      _hasErrors = true;
      _generatorDetailsErrors.gensetModel.push("Choose the proper genset model.");
    }
    if (!generatorSerial) {
      _hasErrors = true;
      _generatorDetailsErrors.generatorSerial.push("Generator serial is required.");
    }

    setHasGeneratorErrors(_hasErrors);
    setGeneratorErrors(_generatorDetailsErrors);
    return _hasErrors;
  }

  const prepareGeneratorPayload = (isArrivalInspection = true) => {
    let _generator = {
      'generator_id': generatorId,
      'genset_model': gensetModelTermSearch,
      'generator_serial': generatorSerial.trim(),
      'manufacturer': generator,
    }
    if (isArrivalInspection) {
      _generator['alternator_serial'] = alternatorSerial ? alternatorSerial.trim() : null;
      _generator['alternator_model'] = alternatorModel ? alternatorModel.trim() : null;
      _generator['alternator'] = alternator;
      _generator['machine_serial'] = machineSerial ? machineSerial.trim() : null;
      _generator['machine_model'] = machineModel ? machineModel.trim() : null;
      _generator['machine'] = machine;
      _generator['controller_serial'] = controllerSerial ? controllerSerial.trim() : null;
      _generator['controller_model'] = controllerModel ? controllerModel.trim() : null;
      _generator['controller'] = controller;
    }
    return _generator;
  }

  const resetGeneratorFields = (includeGenerator = true) => {
    if (includeGenerator) {
      setGensetModel("");
      setGeneratorSerial("");
      setGenerator(generatorManufacturers.AKSA);
    }
    setAlternatorSerial("");
    setAlternatorModel("");
    setAlternator(generatorAlternators.unspecified);
    setMachineSerial("");
    setMachineModel("");
    setMachine(generatorMachines.unspecified);
    setControllerSerial("");
    setControllerModel("");
    setController(generatorControllers.unspecified);
  }

  const onGensetModelSearchTermChanged = (ev, gensetModelTerm, prevGensetModelTerm) => {
    if (gensetModelTerm.trim() === '') {
      setGensetModelTermSearch('');
      setGeneratorId(null);
      return false;
    }
    if (gensetModelTerm.trim() === prevGensetModelTerm.trim()) {
      return false;
    }

    setGensetModelTermSearch(gensetModelTerm);
    setGeneratorId(null);

    return true;
  }

  const onGensetModelSuggestionClicked = (ev, generator) => {
    setGensetModelTermSearch(generator['gensetModel']);
    setGeneratorId(generator['id']);
  }

  const onGeneratorSerialChanged = (ev) => {
    setGeneratorSerial(ev.target.value);
  }

  const onGeneratorChanged = (ev) => {
    setGenerator(parseInt(ev.target.value));
  }

  const onAlternatorSerialChanged = (ev) => {
    setAlternatorSerial(ev.target.value);
  }

  const onAlternatorModelChanged = (ev) => {
    setAlternatorModel(ev.target.value);
  }

  const onAlternatorChanged = (ev) => {
    setAlternator(parseInt(ev.target.value));
  }

  const onMachineSerialChanged = (ev) => {
    setMachineSerial(ev.target.value);
  }

  const onMachineModelChanged = (ev) => {
    setMachineModel(ev.target.value);
  }

  const onMachineChanged = (ev) => {
    setMachine(parseInt(ev.target.value));
  }

  const onControllerSerialChanged = (ev) => {
    setControllerSerial(ev.target.value);
  }

  const onControllerModelChanged = (ev) => {
    setControllerModel(ev.target.value);
  }

  const onControllerChanged = (ev) => {
    setController(parseInt(ev.target.value));
  }
  //#endregion

  //#region Control handlers; Test Details section
  const prepareTestDetailsPayload = () => {
    let _testDetails = {
      'run_hours': runHours !== 0 ? runHours : null,
      'run_hours_min': runHoursMin !== 0 ? runHoursMin : null,
      'engine_rpm': engineRPM !== '' ? parseFloat(engineRPM) : null,
      'fuel': fuel !== '' ? parseFloat(fuel) : null,
      'oil_pressure': oilPressure !== '' ? parseFloat(oilPressure) : null,
      'water_temperature': waterTemperature !== '' ? parseFloat(waterTemperature) : null,
    }
    if (formMode === inspectionFormMode.arrivalInspection) {
      _testDetails['engine_oil'] = engineOil !== '' ? parseFloat(engineOil) : null;
      _testDetails['coolant_water'] = coolantWater !== '' ? parseFloat(coolantWater) : null;
      _testDetails['testing_period_min'] = testingPeriodMin !== 0 ? testingPeriodMin : null;
    }
    if (formMode === inspectionFormMode.predeliveryInspection || formMode === inspectionFormMode.monthly) {
      _testDetails['frequency'] = frequency !== '' ? parseFloat(frequency) : null;
      _testDetails['l1n'] = l1n !== '' ? parseFloat(l1n) : null;
      _testDetails['l2n'] = l2n !== '' ? parseFloat(l2n) : null;
      _testDetails['l3n'] = l2n !== '' ? parseFloat(l2n) : null;
    }

    _testDetails['dc_voltage'] = dcVoltage !== '' ? parseFloat(dcVoltage) : null;
    return _testDetails;
  }

  const resetTestDetailsFields = () => {
    setRunHours(0);
    setRunHoursMin(0);
    setEngineRPM("");
    setFuel("");
    setOilPressure("");
    setWaterTemperature("");

    if (formMode === inspectionFormMode.arrivalInspection) {
      setEngineOil("");
      setCoolantWater("");
      setTestingPeriodMin(0);
    }
    if (formMode === inspectionFormMode.predeliveryInspection) {
      setL1N("");
      setL2N("");
      setL3N("");
    }

    setDCVoltage("");
  }

  const onEngineOilChanged = (ev) => {
    setEngineOil(ev.target.value);
  }

  const onCoolantWaterChanged = (ev) => {
    setCoolantWater(ev.target.value);
  }

  const onFuelChanged = (ev) => {
    setFuel(ev.target.value);
  }

  const onRunHoursChanged = (ev) => {
    setRunHours(parseInt(ev.target.value));
  }

  const onRunHoursMinChanged = (ev) => {
    setRunHoursMin(parseInt(ev.target.value));
  }

  const onOilPressureChanged = (ev) => {
    setOilPressure(ev.target.value);
  }

  const onWaterTemperatureChanged = (ev) => {
    setWaterTemperature(ev.target.value);
  }

  const onEngineRPMChanged = (ev) => {
    setEngineRPM(ev.target.value);
  }

  const onTestingPeriodMinChanged = (ev) => {
    setTestingPeriodMin(parseInt(ev.target.value));
  }

  const onDCVoltageChanged = (ev) => {
    setDCVoltage(ev.target.value);
  }
  //#endregion

  //#region Control handlers; Test Results section
  const prepareTestResultsPayload = () => {
    let _testResults = {
      'check_engine_oil_level': checkEngineOil,
      'check_coolant_level': checkCoolant,
      'check_drive_belt_tension': checkDriveBeltTension,
      'check_batteries_condition': checkBatteriesCondition,
      'check_wiring_connection': checkWiringConnection,
      'check_switch_and_circuit_breaker': checkSwitchNCircuitBreaker,
      'check_oil_coolant_fuel_leaks': checkOilCoolantFuelLeaks,
      'check_hoses_pipes_clamps': checkHosesPipesClamps,
      'check_sensors': checkSensors,
      'check_tighten_holding_down_bolts': checkTightenHoldingDownBolts,
      'check_fuel_drain_plug_seal': checkFuelDrainPlugSeal,
      'check_aksa_tools_kit': checkAKSAToolsKit,
      'check_drawing_manual_books': checkDrawingNManualBooks,
      'check_radiator_head_cover': checkRadiatorHeadCover,
      'check_stickers': checkStickers,
      'check_doors_panel_keys': checkDoorsPanelKeys,
    }
    if (formMode === inspectionFormMode.arrivalInspection) {
      _testResults['l1n'] = l1n !== '' ? parseFloat(l1n) : null;
      _testResults['l2n'] = l2n !== '' ? parseFloat(l2n) : null;
      _testResults['l3n'] = l2n !== '' ? parseFloat(l2n) : null;
      _testResults['l1l2'] = l1l2 !== '' ? parseFloat(l1l2) : null;
      _testResults['l2l3'] = l2l3 !== '' ? parseFloat(l2l3) : null;
      _testResults['l3l1'] = l3l1 !== '' ? parseFloat(l3l1) : null;
      _testResults['frequency'] = frequency !== '' ? frequency : null;
      _testResults['avr_type'] = avrType !== '' ? avrType : null;
      _testResults['governor_type'] = governorType;
    }
    if (formMode === inspectionFormMode.predeliveryInspection) {
      _testResults['check_genset_housekeeping'] = checkGensetHousekeeping;
      _testResults['governor_type'] = null;
    }
    return _testResults;
  }

  const resetTestResultsFields = () => {
    if (formMode === inspectionFormMode.arrivalInspection) {
      setL1N("");
      setL2N("");
      setL3N("");
      setL1L2("");
      setL2L3("");
      setL3L1("");
      setFrequency("");
      setAVRType("");
      setGovernorType(refs.inventory.governorType.electronic);
    }

    setCheckEngineOil(false);
    setCheckCoolant(false);
    setCheckDriveBeltTension(false);
    setCheckBatteriesCondition(false);
    setCheckWiringConnection(false);
    setCheckSwitchNCircuitBreaker(false);
    setCheckOilCoolantFuelLeaks(false);
    setCheckSensors(false);
    setCheckTightenHoldingDownBolts(false);
    setCheckFuelDrainPlugSeal(false);
    setCheckAKSAToolsKit(false);
    setCheckDrawingNManualBooks(false);
    setCheckRadiatorHeadCover(false);
    setCheckStickers(false);
    setCheckDoorsPanelKeys(false);

    if (formMode === inspectionFormMode.predeliveryInspection) {
      setCheckGensetHousekeeping(false);
    }
  }

  const onL1NChanged = (ev) => {
    setL1N(ev.target.value);
  }

  const onL2NChanged = (ev) => {
    setL2N(ev.target.value);
  }

  const onL3NChanged = (ev) => {
    setL3N(ev.target.value);
  }

  const onL1L2Changed = (ev) => {
    setL1L2(ev.target.value);
  }

  const onL2L3Changed = (ev) => {
    setL2L3(ev.target.value);
  }

  const onL3L1Changed = (ev) => {
    setL3L1(ev.target.value);
  }

  const onFrequencyChanged = (ev) => {
    setFrequency(ev.target.value);
  }

  const onAVRTypeChanged = (ev) => {
    setAVRType(ev.target.value);
  }

  const onGovernorTypeChanged = (ev) => {
    setGovernorType(parseInt(ev.target.value));
  }
  //#endregion

  //#region Control handlers; Additional Info
  const prepareAdditionalInfoPayload = () => {
    return {
      'remarks': remarks,
      'disconnect_battery_negative_cable': disconnectBatteryNegative,
      'connect_fuel_section_and_return_pipe': connectFuelSectionReturnPipe,
    }
  }

  const resetAdditionalInfoFields = () => {
    setRemarks("");
    setDisconnectBatteryNegative(false);
    setConnectFuelSectionReturnPipe(false);
  }
  //#endregion

  //#region Render
  return (
    <InspectionFormContext.Provider value={{
      formMode, setFormMode,
      setIsLoading,
      isSubmitting, setIsSubmitting,
      isEditable, setIsEditable,
      setMinEntryDate, setStandbyPower, setProductYear,
      setInspectionType,
      prepareEntryForEditMode,
      isEditableGenerator, setIsEditableGenerator,
      prepareGeneratorForEditMode,
      jobStatus, setJobStatus,
      setTestedBy,
      setCheckedBy,
      setCheckedDate,
      isHeaderFieldsValid,
      isGeneratorDetailsFieldValid,
      prepareHeaderPayload,
      prepareGeneratorPayload,
      prepareTestDetailsPayload,
      prepareTestResultsPayload,
      prepareAdditionalInfoPayload,
    }}>
      <>
        <div className={"row"}>
          <h1>{inspectionTypeTitle} Service Form</h1>
        </div>

        <form>
          <ServiceEntryHeader standbyPower={standbyPower} onStandbyPowerChanged={onStandbyPowerChanged}
                              productYear={productYear} onProductYearChanged={onProductYearChanged}
                              entryDate={entryDate} onEntryDateChanged={onEntryDateChanged} minEntryDate={minEntryDate}
                              inspectionType={inspectionType} onInspectionTypeChanged={onInspectionTypeChanged}
                              testMode={testMode} onTestModeChanged={onTestModeChanged}
                              isLoading={isLoading} isEditable={isEditable} isSubmitting={isSubmitting} hasErrors={hasHeaderErrors} formErrors={headerErrors}
                              formMode={formMode}
          />

          <InspectionEntryGenerator formMode={formMode} jobStatus={jobStatus}
                                    generatorId={generatorId} gensetModel={gensetModelTermSearch}
                                    onGensetModelChanged={onGensetModelSearchTermChanged} onGensetModelSuggestionClicked={onGensetModelSuggestionClicked}
                                    generatorSerial={generatorSerial} onGeneratorSerialChanged={onGeneratorSerialChanged}
                                    generator={generator} onGeneratorChanged={onGeneratorChanged}
                                    alternatorSerial={alternatorSerial} onAlternatorSerialChanged={onAlternatorSerialChanged}
                                    alternatorModel={alternatorModel} onAlternatorModelChanged={onAlternatorModelChanged}
                                    alternator={alternator} onAlternatorChanged={onAlternatorChanged}
                                    machineSerial={machineSerial} onMachineSerialChanged={onMachineSerialChanged}
                                    machineModel={machineModel} onMachineModelChanged={onMachineModelChanged}
                                    machine={machine} onMachineChanged={onMachineChanged}
                                    controllerSerial={controllerSerial} onControllerSerialChanged={onControllerSerialChanged}
                                    controllerModel={controllerModel} onControllerModelChanged={onControllerModelChanged}
                                    controller={controller} onControllerChanged={onControllerChanged}
                                    isLoading={isLoading} isEditable={isEditableGenerator} isSubmitting={isSubmitting} hasErrors={hasGeneratorErrors}
                                    formErrors={generatorErrors}
          />

          {formMode === inspectionFormMode.arrivalInspection &&
            <ServiceEntryArrivalTestDetails engineOil={engineOil} onEngineOilChanged={onEngineOilChanged}
                                            coolantWater={coolantWater} onCoolantWaterChanged={onCoolantWaterChanged}
                                            fuel={fuel} onFuelChanged={onFuelChanged}
                                            runHours={runHours} onRunHoursChanged={onRunHoursChanged}
                                            runHoursMin={runHoursMin} onRunHoursMinChanged={onRunHoursMinChanged}
                                            oilPressure={oilPressure} onOilPressureChanged={onOilPressureChanged}
                                            waterTemperature={waterTemperature} onWaterTemperatureChanged={onWaterTemperatureChanged}
                                            engineRPM={engineRPM} onEngineRPMChanged={onEngineRPMChanged}
                                            testingPeriodMin={testingPeriodMin} onTestingPeriodMinChanged={onTestingPeriodMinChanged}
                                            dcVoltage={dcVoltage} onDCVoltageChanged={onDCVoltageChanged}
                                            isLoading={isLoading} isEditable={isEditable} isSubmitting={isSubmitting}
            />
          }
          {formMode === inspectionFormMode.predeliveryInspection &&
            <ServiceEntryPredeliveryTestDetails runHours={runHours} onRunHoursChanged={onRunHoursChanged}
                                                runHoursMin={runHoursMin} onRunHoursMinChanged={onRunHoursMinChanged}
                                                engineRPM={engineRPM} onEngineRPMChanged={onEngineRPMChanged}
                                                fuel={fuel} onFuelChanged={onFuelChanged}
                                                oilPressure={oilPressure} onOilPressureChanged={onOilPressureChanged}
                                                waterTemperature={waterTemperature} onWaterTemperatureChanged={onWaterTemperatureChanged}
                                                frequency={frequency} onFrequencyChanged={onFrequencyChanged}
                                                l1n={l1n} onL1NChanged={onL1NChanged}
                                                l2n={l2n} onL2NChanged={onL2NChanged}
                                                l3n={l3n} onL3NChanged={onL3NChanged}
                                                dcVoltage={dcVoltage} onDCVoltageChanged={onDCVoltageChanged}
                                                isLoading={isLoading} isEditable={isEditable} isSubmitting={isSubmitting}
            />
          }

          <ServiceEntryTestResults formMode={formMode}
                                   l1n={l1n} onL1NChanged={onL1NChanged}
                                   l2n={l2n} onL2NChanged={onL2NChanged}
                                   l3n={l3n} onL3NChanged={onL3NChanged}
                                   l1l2={l1l2} onL1L2Changed={onL1L2Changed}
                                   l2l3={l2l3} onL2L3Changed={onL2L3Changed}
                                   l3l1={l3l1} onL3L1Changed={onL3L1Changed}
                                   frequency={frequency} onFrequencyChanged={onFrequencyChanged}
                                   avrType={avrType} onAVRTypeChanged={onAVRTypeChanged}
                                   governorType={governorType} onGovernorTypeChanged={onGovernorTypeChanged}
                                   checkEngineOil={checkEngineOil} onCheckEngineOil={setCheckEngineOil}
                                   checkCoolant={checkCoolant} onCheckCoolantChanged={setCheckCoolant}
                                   checkDriveBeltTension={checkDriveBeltTension} onCheckDriveBeltTensionChanged={setCheckDriveBeltTension}
                                   checkBatteriesCondition={checkBatteriesCondition} onCheckBatteriesConditionChanged={setCheckBatteriesCondition}
                                   checkWiringConnection={checkWiringConnection} onCheckWiringConnectionChanged={setCheckWiringConnection}
                                   checkSwitchNCircuitBreaker={checkSwitchNCircuitBreaker} onCheckSwitchNCircuitBreakerChanged={setCheckSwitchNCircuitBreaker}
                                   checkOilCoolantFuelLeaks={checkOilCoolantFuelLeaks} onCheckOilCoolantFuelLeaksChanged={setCheckOilCoolantFuelLeaks}
                                   checkHosesPipesClamps={checkHosesPipesClamps} onCheckHosesPipesClamps={setCheckHosesPipesClamps}
                                   checkGensetHousekeeping={checkGensetHousekeeping} onCheckGensetHousekeepingChanged={setCheckGensetHousekeeping}
                                   checkSensors={checkSensors} onCheckSensorsChanged={setCheckSensors}
                                   checkTightenHoldingDownBolts={checkTightenHoldingDownBolts}
                                   onCheckTightenHoldingDownBoltsChanged={setCheckTightenHoldingDownBolts}
                                   checkFuelDrainPlugSeal={checkFuelDrainPlugSeal} onCheckFuelDrainPlugSealChanged={setCheckFuelDrainPlugSeal}
                                   checkAKSAToolsKit={checkAKSAToolsKit} onCheckAKSAToolsKitChanged={setCheckAKSAToolsKit}
                                   checkDrawingManualBooks={checkDrawingNManualBooks} onCheckDrawingManualBooks={setCheckDrawingNManualBooks}
                                   checkRadiatorHeadCover={checkRadiatorHeadCover} onCheckRadiatorHeadCoverChanged={setCheckRadiatorHeadCover}
                                   checkStickers={checkStickers} onCheckStickersChanged={setCheckStickers}
                                   checkDoorsPanelsKeys={checkDoorsPanelKeys} onCheckDoorsPanelsKeysChanged={setCheckDoorsPanelKeys}
                                   isLoading={isLoading} isEditable={isEditable} isSubmitting={isSubmitting}
          />

          <ServiceEntryAdditionalInfo remarks={remarks} onRemarksChanged={(ev) => setRemarks(ev.target.value)}
                                      disconnectBatteryNegative={disconnectBatteryNegative}
                                      onDisconnectBatteryNegative={(ev) => setDisconnectBatteryNegative(ev.target.checked)}
                                      connectFuelSectionReturnPipe={connectFuelSectionReturnPipe}
                                      onConnectFuelSectionReturnPipeChanged={(ev) => setConnectFuelSectionReturnPipe(ev.target.checked)}
                                      jobStatus={jobStatus}
                                      testedBy={testedBy}
                                      isLoading={isLoading} isEditable={isEditable} isSubmitting={isSubmitting}
          />

          <ServiceEntrySignature jobStatus={jobStatus}
                                 testedBy={testedBy}
                                 checkedBy={checkedBy} checkedDate={checkedDate}
          />

          {children}

        </form>
      </>
    </InspectionFormContext.Provider>
  )
  //#endregion
}

export const useInspectionForm = () => {
  return useContext(InspectionFormContext);
}

const InspectionEntryGenerator = ({
                                    formMode, jobStatus, generatorId,
                                    gensetModel, onGensetModelChanged, onGensetModelSuggestionClicked,
                                    generatorSerial, onGeneratorSerialChanged,
                                    generator, onGeneratorChanged,
                                    alternatorSerial, onAlternatorSerialChanged,
                                    alternatorModel, onAlternatorModelChanged,
                                    alternator, onAlternatorChanged,
                                    machineSerial, onMachineSerialChanged,
                                    machineModel, onMachineModelChanged,
                                    machine, onMachineChanged,
                                    controllerSerial, onControllerSerialChanged,
                                    controllerModel, onControllerModelChanged,
                                    controller, onControllerChanged,
                                    isLoading, isEditable, isSubmitting, hasErrors, formErrors,
                                  }) => {
  const [generatorSuggestions, setGeneratorSuggestions] = useState([]);

  const getGensetModelClassName = () => {
    let className = "form-control md-field";
    if (generatorId) className += " has-autocompleted-value";
    return className;
  }

  const expandSuggestionsResult = (elementId, suggestionsElementId) => {
    setTimeout(() => {
      let fieldElement = document.getElementById(elementId);
      let suggestionsElement = document.getElementById(suggestionsElementId);
      if (suggestionsElement) {
        suggestionsElement.style.width = `${fieldElement.offsetWidth}px`;
        suggestionsElement.style.left = `${fieldElement.offsetLeft}px`;
      }
    }, 100);
  }

  let _prevGensetModelTerm = '';
  let _gensetModelTerm = '';
  const onGensetModelTermFieldChanged = (ev) => {
    _prevGensetModelTerm = _gensetModelTerm.trim();
    _gensetModelTerm = ev.target.value.trim();

    let isGensetModelChanged = onGensetModelChanged(ev, _gensetModelTerm, _prevGensetModelTerm);
    if (!isGensetModelChanged) return;

    if (_gensetModelTerm.length >= 2) {
      refServices2.searchGenerators(_gensetModelTerm)
        .then((response) => {
          let suggestions = response.data;
          setGeneratorSuggestions(suggestions);
          expandSuggestionsResult('searchGensetModel', 'gensetModelSuggestions');
        })
        .catch((error) => {
          setGeneratorSuggestions([]);
        });
    } else {
      setGeneratorSuggestions([]);
    }
  }

  const onGensetModelTermBlurred = (ev) => {
    setTimeout(() => {
      setGeneratorSuggestions([]);
    }, 200);
  }

  const onGensetModelFieldClicked = (ev) => {
    setGeneratorSuggestions([]);
    expandSuggestionsResult('searchGensetModel', 'gensetModelSuggestions');
  }

  const onGensetModelClicked = (ev, generator) => {
    onGensetModelSuggestionClicked(ev, generator);
    setGeneratorSuggestions([]);
  }

  return (
    <div className={"form-section"}>
      <h2>Generator Details</h2>

      {formMode === inspectionFormMode.arrivalInspection && jobStatus === refs.inventory.stockStatus.draft &&
        <InfoMessage>
          Make sure to search and select a proper <b>genset model</b> before entering the serial numbers.
        </InfoMessage>
      }

      <div className={"entry-form arrival-inspection-form generator-section"}>
      </div>

      <div className={"entry-form arrival-inspection-form generator-section"}>
        <div className={"form-label"}>
          <label htmlFor={"generatorModel"}>
            Generator Model:<span className="error-message">*</span>
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"generatorSerial"}>
            Generator Serial Number:<span className="error-message">*</span>
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"generator"}>
            Generator/Manufacturer:<span className={"error-message"}>*</span>
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"alternatorSerial"}>
            Alternator Serial Number:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"alternatorModel"}>
            Alternator Model:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"alternator"}>
            Alternator:
          </label>
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{gensetModel}</ReadonlyField>}
          {isEditable &&
            <>
              <input type={"text"} id={"searchGensetModel"} className={getGensetModelClassName()}
                     autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                     placeholder={"Type genset model to search"}
                     value={gensetModel} onChange={onGensetModelTermFieldChanged} onBlur={onGensetModelTermBlurred}
                     onClick={onGensetModelFieldClicked} />
              <FieldErrorMessages visible={hasErrors} messages={formErrors['gensetModel']} />
              {generatorSuggestions && generatorSuggestions.length > 0 &&
                <AutoCompleteResults id={"gensetModelSuggestions"} style={{ width: '400px' }}
                                     collection={generatorSuggestions} itemDisplayField={'gensetModel'}
                                     onItemClicked={onGensetModelClicked} />
              }
            </>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{generatorSerial}</ReadonlyField>}
          {isEditable &&
            <>
              <input type={"text"} id={"generatorSerial"} className={"form-control"}
                     autoComplete={"off"} maxLength={100} disabled={isLoading || isSubmitting}
                     value={generatorSerial} onChange={onGeneratorSerialChanged} />
              <FieldErrorMessages visible={hasErrors} messages={formErrors['generatorSerial']} />
            </>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{generatorManufacturers[generator]}</ReadonlyField>}
          {isEditable &&
            <select id={"generator"} name={"generator"} className={"form-control form-select"}
                    disabled={isLoading || isSubmitting}
                    value={generator} onChange={onGeneratorChanged}>
              <option value={generatorManufacturers.AKSA}>{generatorManufacturers[generatorManufacturers.AKSA]}</option>
              <option value={generatorManufacturers.DANYO}>{generatorManufacturers[generatorManufacturers.DANYO]}</option>
              <option value={generatorManufacturers.newHolland}>{generatorManufacturers[generatorManufacturers.newHolland]}</option>
              <option value={generatorManufacturers.kohler}>{generatorManufacturers[generatorManufacturers.kohler]}</option>
              <option value={generatorManufacturers.powerLink}>{generatorManufacturers[generatorManufacturers.powerLink]}</option>
              <option value={generatorManufacturers.local}>{generatorManufacturers[generatorManufacturers.local]}</option>
            </select>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField><NullBlankValue value={alternatorSerial} /></ReadonlyField>}
          {isEditable &&
            <input type={"text"} id={"alternatorSerial"} className={"form-control"}
                   autoComplete={"off"} maxLength={100} disabled={isLoading || isSubmitting}
                   value={alternatorSerial} onChange={onAlternatorSerialChanged} />
          }
        </div>

        <div className={"form-input"}>
          {!isEditable &&
            <ReadonlyField><NullBlankValue value={alternatorModel} /></ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"alternatorModel"} className={"form-control"}
                   autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                   value={alternatorModel} onChange={onAlternatorModelChanged} />
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{generatorAlternators[alternator]}</ReadonlyField>}
          {isEditable &&
            <select id={"alternator"} name={"alternator"} className={"form-control form-select"}
                    disabled={isLoading || isSubmitting}
                    value={alternator} onChange={onAlternatorChanged}>
              <option value={generatorAlternators.unspecified}>{generatorAlternators[generatorAlternators.unspecified]}</option>
              <option value={generatorAlternators.AKSA}>{generatorAlternators[generatorAlternators.AKSA]}</option>
              <option value={generatorAlternators.Stamford}>{generatorAlternators[generatorAlternators.Stamford]}</option>
              <option value={generatorAlternators.meccAlte}>{generatorAlternators[generatorAlternators.meccAlte]}</option>
              <option value={generatorAlternators.leroySomer}>{generatorAlternators[generatorAlternators.leroySomer]}</option>
              <option value={generatorAlternators.marathon}>{generatorAlternators[generatorAlternators.marathon]}</option>
              <option value={generatorAlternators.copyStamford}>{generatorAlternators[generatorAlternators.copyStamford]}</option>
              <option value={generatorAlternators.fpt}>{generatorAlternators[generatorAlternators.fpt]}</option>
              <option value={generatorAlternators.hokuetsu}>{generatorAlternators[generatorAlternators.hokuetsu]}</option>
              <option value={generatorAlternators.brushless}>{generatorAlternators[generatorAlternators.brushless]}</option>
            </select>
          }
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"machineSerial"}>
            Machine Serial Number:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"machineModel"}>
            Machine Model:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"machine"}>
            Machine:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"controllerSerial"}>
            Controller Serial Number:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"controllerModel"}>
            Controller Model:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"controller"}>
            Controller:
          </label>
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField><NullBlankValue value={machineSerial} /></ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"machineSerial"} className={"form-control"}
                   autoComplete={"off"} maxLength={100} disabled={isLoading || isSubmitting}
                   value={machineSerial} onChange={onMachineSerialChanged} />
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField><NullBlankValue value={machineModel} /></ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"machineModel"} className={"form-control"}
                   autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                   value={machineModel} onChange={onMachineModelChanged} />
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField>{generatorMachines[machine]}</ReadonlyField>
          }
          {isEditable &&
            <select id={"machine"} name={"machine"} className={"form-control form-select"}
                    disabled={isLoading || isSubmitting}
                    value={machine} onChange={onMachineChanged}>
              <option value={generatorMachines.unspecified}>{generatorMachines[generatorMachines.unspecified]}</option>
              <option value={generatorMachines.AKSA}>{generatorMachines[generatorMachines.AKSA]}</option>
              <option value={generatorMachines.Cummins}>{generatorMachines[generatorMachines.Cummins]}</option>
              <option value={generatorMachines.Mitsubishi}>{generatorMachines[generatorMachines.Mitsubishi]}</option>
              <option value={generatorMachines.Perkins}>{generatorMachines[generatorMachines.Perkins]}</option>
              <option value={generatorMachines.volvo}>{generatorMachines[generatorMachines.volvo]}</option>
              <option value={generatorMachines.fpt}>{generatorMachines[generatorMachines.fpt]}</option>
              <option value={generatorMachines.johnDeere}>{generatorMachines[generatorMachines.johnDeere]}</option>
              <option value={generatorMachines.isuzu}>{generatorMachines[generatorMachines.isuzu]}</option>
              <option value={generatorMachines.kubota}>{generatorMachines[generatorMachines.kubota]}</option>
              <option value={generatorMachines.hino}>{generatorMachines[generatorMachines.hino]}</option>
              <option value={generatorMachines.weichai}>{generatorMachines[generatorMachines.weichai]}</option>
            </select>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField><NullBlankValue value={controllerSerial} /></ReadonlyField>}
          {isEditable &&
            <input type={"text"} id={"controllerSerial"} className={"form-control"}
                   autoComplete={"off"} maxLength={100} disabled={isLoading || isSubmitting}
                   value={controllerSerial} onChange={onControllerSerialChanged} />
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField><NullBlankValue value={controllerModel} /></ReadonlyField>
          }
          {isEditable &&
            <input type={"text"} id={"controllerModel"} className={"form-control"}
                   autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                   value={controllerModel} onChange={onControllerModelChanged} />
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable &&
            <ReadonlyField>{generatorControllers[controller]}</ReadonlyField>
          }
          {isEditable &&
            <select id={"machine"} name={"machine"} className={"form-control form-select"}
                    disabled={isLoading || isSubmitting}
                    value={controller} onChange={onControllerChanged}>
              <option value={generatorControllers.unspecified}>{generatorControllers[generatorControllers.unspecified]}</option>
              <option value={generatorControllers.deepSea}>{generatorControllers[generatorControllers.deepSea]}</option>
              <option value={generatorControllers.compAp}>{generatorControllers[generatorControllers.compAp]}</option>
              <option value={generatorControllers.smartGen}>{generatorControllers[generatorControllers.smartGen]}</option>
              <option value={generatorControllers.kohler}>{generatorControllers[generatorControllers.kohler]}</option>
              <option value={generatorControllers.deif}>{generatorControllers[generatorControllers.deif]}</option>
              <option value={generatorControllers.fpt}>{generatorControllers[generatorControllers.fpt]}</option>
              <option value={generatorControllers.analog}>{generatorControllers[generatorControllers.analog]}</option>
            </select>
          }
        </div>

      </div>
    </div>
  )
}
